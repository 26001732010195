import React from "react";
import { Tab, Tabs } from "react-bootstrap";

export default function CustomTimePicker({
  hourTime,
  mintsTime,
  selectedTab,
  setHourTime,
  setMintsTime,
  setSelectedTab,
  onChange,
}) {
  return (
    <div
      className="d-flex justify-content-start align-items-center"
      style={{ gap: 10 }}
    >
      <select
        className="time-input-1"
        value={hourTime}
        onChange={(e) => {
          setHourTime(e.target.value);
          onChange && onChange({ hourTime: e.target.value });
        }}
      >
        {/* Using a loop in JSX to generate options from 01 to 12 */}
        <option value={""}>hour</option>

        {Array.from({ length: 12 }, (_, index) => (
          <>
            <option key={index + 1}>
              {(index + 1).toString().padStart(2, "0")}
            </option>
          </>
        ))}
      </select>
      <div className="time-colon">:</div>
      <select
        className="time-input-2"
        value={mintsTime}
        onChange={(e) => {
          setMintsTime(e.target.value);
          onChange && onChange({ mintsTime: e.target.value });
        }}
      >
        <option value={""}>min</option>

        {Array.from({ length: 60 }, (_, index) => (
          <option key={index}>{index.toString().padStart(2, "0")}</option>
        ))}
      </select>

      <Tabs
        id="controlled-tab-example"
        activeKey={selectedTab}
        onSelect={(k) => setSelectedTab(k)}
        className=""
      >
        <Tab eventKey="am" title="AM"></Tab>
        <Tab eventKey="pm" title="PM"></Tab>
      </Tabs>
    </div>
  );
}

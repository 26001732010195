import React, { useState } from "react";
import { Link } from "react-router-dom";
import profile from "../../../images/user-icon3.png";
import { Dropdown } from "react-bootstrap";
import ChangePassword from "../../modal/ChangePassword";
import LogoutPage from "./Logout";
const NavHader = () => {
  const [toggle, setToggle] = useState(false);
  const [changePassword, setChangePassword] = useState(false);
  return (
    <div className="nav-header" style={{ zIndex: "12" }}>
      {/* <Link to="/" className="brand-logo">
            <img className="logo-abbr" src={logo} alt="" />
            {/* <img className="logo-compact ml-5" src={logoText} alt="" /> *
            <img className="brand-title " src={logoText} alt="" />
         </Link> */}

      <ul className="navbar-nav header-right">
        <Dropdown as="li" className="nav-item dropdown header-profile">
          <Dropdown.Toggle
            variant=""
            as="a"
            className="i-false c-pointer nav-link"
            to=""
            role="button"
            data-toggle="dropdown"
          >
            <img src={profile} width={20} alt="" />
            {!toggle ? (
              <div className="header-info ml-1">
                <span>
                  <strong>Admin</strong>
                </span>
              </div>
            ) : (
              ""
            )}
          </Dropdown.Toggle>
          <Dropdown.Menu
            align="right"
            className="dropdown-menu dropdown-menu-right"
          >
            <Link
              className="dropdown-item ai-icon"
              onClick={() => setChangePassword(true)}
              style={{ backgroundColor: "#fff" }}
            >
              <svg
                id="icon-password"
                xmlns="http://www.w3.org/2000/svg"
                className="text-primary"
                width={18}
                height={18}
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth={2}
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
                <polyline points="16 17 21 12 16 7" />
                <line x1={21} y1={12} x2={9} y2={12} />
              </svg>
              <span className="ml-2">Change Password </span>
            </Link>
            <LogoutPage />
          </Dropdown.Menu>
        </Dropdown>
      </ul>

      <div className="nav-control" onClick={() => setToggle(!toggle)}>
        <div className={`hamburger ${toggle ? "is-active" : ""}`}>
          <span className="line"></span>
          <span className="line"></span>
          <span className="line"></span>
        </div>
      </div>
      <ChangePassword
        password={changePassword}
        onHide={() => setChangePassword(false)}
      />
    </div>
  );
};

export default NavHader;

import React, { useRef, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { colorRangeApi } from "../../services/Category/CategoryService";
import { notifyError, notifyTopRight } from "../common/Toaster";

export default function ColorRangeModal({ show, path, onHide, data, refresh }) {
  const [greenColor, setGreenColor] = useState({
    from: data?.green?.from,
    to: data?.green?.to,
  });
  const [yellowColor, setYellowColor] = useState({
    from: data?.yellow?.from,
    to: data?.yellow?.to,
  });
  const [redColor, setRedColor] = useState({
    from: data?.red?.from,
    to: data?.red?.to,
  });
  const [loader, setLoader] = useState(false);

  async function handleUploadFile(e) {
    e.preventDefault();
    setLoader(true);
    try {
      const response = await colorRangeApi(
        data?._id,
        greenColor,
        redColor,
        yellowColor
      );
      refresh();
      notifyTopRight("Color Updated Success");
      onHide();
    } catch (e) {
      notifyError(e?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  }

  return (
    <Modal
      className="modal fade color-modal"
      show={show}
      onHide={onHide}
      centered
    >
      <div className="" role="document">
        <div className="">
          <div className="modal-body">
            <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
            <div className="add-contact-box p-3">
              <h4 className="modal-heading">Colour ranges</h4>
              <p className="error-text">{path}</p>
              <div className="mb-5">
                <div className="color-range-fields">
                  <p>Green</p>
                  <input
                    type="text"
                    value={greenColor?.from}
                    onChange={(e) =>
                      setGreenColor({
                        ...greenColor,
                        from: e.target.value,
                      })
                    }
                  />
                  <h3>To</h3>
                  <input
                    type="text"
                    value={greenColor?.to}
                    onChange={(e) =>
                      setGreenColor({
                        ...greenColor,
                        to: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="color-range-fields">
                  <p>Yellow</p>
                  <input
                    type="text"
                    value={yellowColor?.from}
                    onChange={(e) =>
                      setYellowColor({
                        ...yellowColor,
                        from: e.target.value,
                      })
                    }
                  />
                  <h3>To</h3>
                  <input
                    type="text"
                    value={yellowColor?.to}
                    onChange={(e) =>
                      setYellowColor({
                        ...yellowColor,
                        to: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="color-range-fields">
                  <p>Red</p>
                  <input
                    type="text"
                    value={redColor?.from}
                    onChange={(e) =>
                      setRedColor({
                        ...redColor,
                        from: e.target.value,
                      })
                    }
                  />
                  <h3>To</h3>
                  <input
                    type="text"
                    value={redColor?.to}
                    onChange={(e) =>
                      setRedColor({
                        ...redColor,

                        to: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div className="d-flex">
                <button
                  type="submit"
                  className="btn theme-btn w-100"
                  onClick={handleUploadFile}
                >
                  {loader ? <Spinner animation="border" size="sm" /> : "OK"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

import React, { Component, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import MetisMenu from "metismenujs";
import { getProfile } from "../../../services/Profiles/ProfileService";

import sideIcon from "../../../images/svg/ChartPieSlice.svg";
import sideSubIcon from "../../../images/svg/ShoppingBagOpen.svg";
import { setPageTitleAction } from "../../../store/actions/AuthActions";
import { useDispatch } from "react-redux";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }

  componentWillUnmount() {}

  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

const SideBar = () => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  // Function to fetch profiles
  const handleFetchProfiles = () => {
    getProfile()
      .then((response) => {
        setData(response.data.data);
      })
      .catch((error) => {
        console.log(error.response, "helooooooooo");
      });
  };

  useEffect(() => {
    // Function to toggle sidebar open/close
    const toggleFunc = () => {
      const aaa = document.querySelector("#main-wrapper");
      if (aaa) {
        aaa.classList.toggle("menu-toggle");
      }
    };

    const btn = document.querySelector(".nav-control");
    btn.addEventListener("click", toggleFunc);

    handleFetchProfiles();

    return () => {
      if (btn) {
        btn.removeEventListener("click", toggleFunc);
      }
    };
  }, []);
  let path = window.location.pathname;
  path = path.split("/");
  path = path[path.length - 1];

  let dashBoard = [
    "",
    "analytics",
    "events",
    "order-list",
    "general-customers",
    "reviews",
    "task",
  ];
  let users = ["question-library"];
  let profile = ["profile"];

  return (
    <div className="deznav">
      <PerfectScrollbar className="deznav-scroll">
        <MM className="metismenu" id="menu">
          {/* Your menu items */}
          <li className={`${dashBoard.includes(path) ? "mm-active" : ""}`}>
            <Link
              className="ai-icon mx-3"
              to="/"
              onClick={() => {
                dispatch(setPageTitleAction(`Dashboard / default`));
              }}
            >
              <img src={sideIcon} alt="Dashboard Icon" />
              <span className="nav-text pl-1">Dashboard</span>
            </Link>
          </li>

          <li className={`${users.includes(path) ? "mm-active" : ""}`}>
            <Link
              to="question-library"
              className="ai-icon mx-3"
              onClick={() => {
                dispatch(setPageTitleAction(`Question library /`));
              }}
            >
              <img src={sideIcon} alt="User Management Icon" />
              <span className="nav-text pl-1">Question library</span>
            </Link>
          </li>

          <li className={`${profile.includes(path) ? "mm-active" : ""}`}>
            <Link className="has-arrow ai-icon mx-3" to="#">
              <img src={sideIcon} alt="Profiles Icon" />
              <span className="nav-text pl-1">Profiles</span>
            </Link>
            <ul>
              {data?.map((item) => (
                <li key={item._id}>
                  <Link
                    className={`${path === "profile" ? "mm-active" : ""}`}
                    to={{ pathname: "/profile", state: { item } }}
                    onClick={() => {
                      dispatch(setPageTitleAction(`Profiles / ${item?.name}`));
                    }}
                  >
                    <img src={sideSubIcon} alt="Sub Icon" />
                    <span className="pl-1">{item?.name}</span>
                  </Link>
                </li>
              ))}
            </ul>
          </li>
        </MM>
      </PerfectScrollbar>
    </div>
  );
};

export default SideBar;

import React, { useState, useEffect } from "react";
import AWS from "aws-sdk";
// import { KEYCHAIN } from '../utils/keychain';

const S3Image = ({ imageKey, title }) => {
  const [imageData, setImageData] = useState(null);

  console.log(imageKey);

  useEffect(() => {
    console.log(imageKey);
    const fetchImageData = async () => {
      try {
        const s3 = new AWS.S3();
        const params = {
          Bucket: "biteluxe",
          Key: imageKey,
        };
        const data = await s3.getObject(params).promise();
        const base64Image = data.Body.toString("base64");

        setImageData(base64Image);
      } catch (error) {
        console.error("Error fetching image:", error);
      }
    };

    fetchImageData();
  }, [imageKey]);

  //   if (!imageData) {
  //     return (
  //       <div>
  //         <img height={60} width={60} src={defaultImage} />
  //       </div>
  //     );
  //   }

  return (
    <div className="logo-container">
      <img src={`data:image/png;base64,${imageData}`} alt="S3 Content" />
    </div>
  );
};

export default S3Image;

import React, { useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";
import Spinner from "../common/Spinner";
import { useDispatch } from "react-redux";
import editIcon from "../../images/svg/edit.svg";
import AddCategory from "../modal/AddCategory";
import AddQuestion from "../modal/AddQuestion";
import {
  createLibraryCategory,
  createLibraryQuestions,
  getLibraryCategory,
  getLibraryQuestions,
  editLibraryCategory,
} from "../../services/QuestionLibrary/LibraryService";
export default function UserManagement(props) {
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [categoryModal, setCategoryModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [search, setSearch] = useState("");
  const [queModal, setQueModal] = useState(false);
  const [categoryId, setCategoryId] = useState();
  const [showTable, setShowTable] = useState("");
  const [editQuestionModal, setEditQuestionModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const limit = 0;

  ////////  get category list   ///////////
  const getCategoryFun = async () => {
    setLoader(true);
    try {
      const response = await getLibraryCategory(currentPage, limit);
      console.log(response.data.data, "get response");
      setCategories(response?.data?.data);
      setLoader(false);
    } catch (error) {
      console.log(error, "get category error ");
    } finally {
      setLoader(false);
    }
  };

  ////////  get question list   ///////////
  const getQuestionFun = async (id) => {
    setLoader2(true);
    try {
      const response = await getLibraryQuestions(id, currentPage, limit);
      setQuestions(response?.data?.data);
      setLoader2(false);
    } catch (error) {
      console.log(error, "get error ");
      setLoader2(false);
    }
  };

  function handleShowTable(item) {
    console.log(item);
    setSelectedItem(item);
    setShowTable(item?.name);
    getQuestionFun(item?._id);
  }

  useEffect(() => {
    getCategoryFun();
  }, [currentPage]);

  return (
    <div>
      <div className="d-flex justify-content-between" style={{ gap: "2rem" }}>
        <Card className="w-50">
          <Card.Header className="d-block">
            <h3>Categories</h3>
            <button
              type="button"
              className="outline-btn mt-3"
              onClick={() => setCategoryModal(true)}
            >
              Add Category
            </button>
          </Card.Header>
          <Card.Body className="pt-0">
            {loader ? (
              <Spinner />
            ) : (
              <div className="custom-scrollbar">
                <Table responsive>
                  <tbody>
                    {categories?.map((item, i) => (
                      <tr
                        key={i}
                        className={`pointer ${
                          selectedItem === item ? "highlighted-row" : ""
                        }`}
                      >
                        <td
                          style={{ width: "50px" }}
                          onClick={() => {
                            setEditQuestionModal(true);
                            setCategoryId(item);
                          }}
                        >
                          <img src={editIcon} className="" />
                        </td>
                        <td
                          style={{ borderTop: "none" }}
                          className="border-bottom"
                          onClick={() => handleShowTable(item)}
                        >
                          {item?.name}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            )}
          </Card.Body>
        </Card>
        {showTable && (
          <Card className="w-100">
            <Card.Header className="d-block">
              <h3>Questions</h3>
              <button
                type="button"
                className="outline-btn mt-3"
                onClick={() => setQueModal(true)}
              >
                Add Question
              </button>
            </Card.Header>
            <Card.Body className="pt-0">
              {loader2 ? (
                <Spinner />
              ) : (
                <Table responsive>
                  <tbody>
                    {questions?.map((item, i) => (
                      <tr key={i}>
                        <td
                          style={{ borderTop: "none" }}
                          className="border-bottom"
                        >
                          {item?.question}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Card.Body>
          </Card>
        )}
      </div>

      {categoryModal && (
        <AddCategory
          title={"Add new Category"}
          path={"Question library"}
          title2={"Current Categories"}
          toast={"Added library Successfully"}
          apiFunc={createLibraryCategory}
          table={getCategoryFun}
          show={categoryModal}
          onHide={() => setCategoryModal(false)}
          categoryList={categories}
        />
      )}
      {editQuestionModal && (
        <AddCategory
          title={"Edit Category"}
          path={"Question library"}
          title2={"Current Categories"}
          toast={"Edited library Successfully"}
          apiFunc={editLibraryCategory}
          table={getCategoryFun}
          id={categoryId}
          show={editQuestionModal}
          onHide={() => setEditQuestionModal(false)}
          categoryList={categories}
        />
      )}
      {queModal && (
        <AddQuestion
          show={queModal}
          id={selectedItem._id}
          table={getQuestionFun}
          apiFunc={createLibraryQuestions}
          onHide={() => setQueModal(false)}
        />
      )}
    </div>
  );
}

import React from "react";
import { Modal } from "react-bootstrap";

export default function ErrorModal({ show,errorMessage, onHide }) {
  return (
    <Modal className="modal fade" show={show} onHide={onHide} centered>
      <div className="" role="document">
        <div className="">
          <div className="modal-body">
            <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
            <div className="add-contact-box p-3">
              <h4 className="modal-heading">Error</h4>
              <p className="error-text">
                {errorMessage}
              </p>
              <div className="">
                <button
                  type="button"
                  className="btn theme-btn w-100"
                  onClick={onHide}
                >
                  OK
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

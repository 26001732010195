import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { notifyError, notifyTopRight } from "../common/Toaster";

export default function AddCategory({
  title,
  title2,
  show,
  path,
  table,
  onHide,
  id,
  hotelId,
  apiFunc,
  categoryList,
  getAreasFun,
  toast,
}) {
  const [name, setName] = useState(id?.name);
  const [loader, setLoader] = useState(false);
  let errorsObj = {
    name: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  async function onSubmit(e) {
    e.preventDefault();

    let error = false;
    const errorObj = { ...errorsObj };
    if (name === "") {
      errorObj.name = "Name is Required !";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    setLoader(true);
    if (title === "Create Category") {
      try {
        const response = await apiFunc(name, id);
        notifyTopRight("Added Successfully");
        setName("");
        table(hotelId);
        onHide();
      } catch (error) {
        notifyError(error?.response?.data?.message);
      } finally {
        setLoader(false);
      }
    } else if (title === "Add new Category") {
      try {
        const response = await apiFunc(name, id?._id);
        notifyTopRight(toast);
        setName("");
        table();
        onHide();
      } catch (error) {
        notifyError(error?.response?.data?.message);
        setLoader(false);
      }
    } else if (title === "Edit Category") {
      try {
        const response = await apiFunc(name, id?._id);
        notifyTopRight(toast);
        setName("");
        table();
        onHide();
      } catch (error) {
        notifyError(error?.response?.data?.message);
        setLoader(false);
      }
    } else {
      try {
        const response = await apiFunc(name, id);
        notifyTopRight("Added Successfully");
        setName("");
        table(hotelId);
        onHide();
        getAreasFun(id);
      } catch (error) {
        notifyError(error?.response?.data?.message);
        setLoader(false);
      }
    }
  }
  return (
    <Modal className="modal fade" show={show} onHide={onHide} centered>
      <div className="" role="document">
        <div className="">
          <form onSubmit={onSubmit}>
            {/* <div className="modal-header">
              
              {/* <button type="button" className="btn-close"  data-dismiss="modal"><span></span></button> *
            </div> */}
            <div className="modal-body">
              <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
              <h3 className="">{title}</h3>
              <p>{path}</p>
              <div className="add-contact-box">
                <div className="add-contact-content">
                  <div className="form-group mt-3 mb-3">
                    {/* <label className="text-black font-w500">
                      Question library
                    </label> */}
                    <div className="contact-name">
                      <input
                        type="text"
                        className="form-input"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        // placeholder="Enter category"
                      />
                      <span className="validation-text"></span>
                      {errors.name && (
                        <div className="text-danger fs-12">{errors.name}</div>
                      )}
                    </div>
                  </div>
                  <div className="">
                    <button
                      type="submit"
                      className="btn theme-btn w-100"
                      // onClick={onChangePassword}
                    >
                      {loader ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "ADD"
                      )}
                    </button>
                  </div>
                </div>
                <div>
                  <h3 className="mt-5">{title2}</h3>
                  <div>
                    {categoryList?.map((item) => {
                      return (
                        <div className="border-bottom p-3">{item?.name}</div>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}

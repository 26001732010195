import { title } from "process";
import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import {
  editDailyGestApi,
  editSnapPointAlertApi,
} from "../../services/Hotels/HotelsService";
import { notifyError, notifyTopRight } from "../common/Toaster";

export default function EditDailyGest({ title, show, onHide, data, refresh }) {
  const [dailyGest, setDailyGest] = useState(data?.dailyGuests);
  const [loading, setLoading] = useState(false);
  const [snapPointAlert, setSnapPointAlert] = useState(data?.snapPointAlert);

  async function handleUpdateGest() {
    setLoading(true);
    if (title === "Update daily Guests") {
      console.log("first");
      try {
        const response = await editDailyGestApi(data?._id, dailyGest);
        if (response.status == 200) {
          setLoading(false);
          refresh();
          notifyTopRight("Updated Successfully");
          onHide();
        }
      } catch (error) {
        notifyError(error?.response?.data?.message);
        setLoading(false);
      }
    } else {
      console.log("sec");

      try {
        const response = await editSnapPointAlertApi(data?._id, snapPointAlert);
        if (response.status == 200) {
          setLoading(false);
          refresh();
          notifyTopRight("Updated Successfully");
          onHide();
        }
      } catch (error) {
        notifyError(error?.response?.data?.message);
        setLoading(false);
      }
    }
  }

  return (
    <Modal className="modal fade" show={show} onHide={onHide} centered>
      <div className="" role="document">
        <div className="">
          <div className="modal-body">
            <h3 className="text-center m-0 mb-3">{title}</h3>
            <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
            <div
              className="d-flex flex-column align-items-center"
              style={{ gap: "15px" }}
            >
              {title === "Update daily Guests" ? (
                <input
                  type="number"
                  value={dailyGest}
                  className="form-control"
                  onChange={(e) => setDailyGest(e.target.value)}
                />
              ) : (
                <input
                  type="number"
                  value={snapPointAlert}
                  className="form-control"
                  onChange={(e) => setSnapPointAlert(e.target.value)}
                />
              )}
              <div className="">
                <button
                  type="submit"
                  className="btn btn-sm btn-secondary"
                  onClick={handleUpdateGest}
                >
                  {loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "Update"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

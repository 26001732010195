import { SNAP_GROUP } from "../AuthApiEndPoints";
import axiosInstance from "../AxiosInstance";

export function getSnapGroups(id, startDate, endDate) {
  return axiosInstance.get(
    SNAP_GROUP + `?categoryId=${id}&startDate=${startDate}&endDate=${endDate}`
  );
}

export function createSnapGroups(id, hotelId) {
  const postData = {
    categoryId: id,
    hotelId,
  };
  return axiosInstance.post(SNAP_GROUP, postData);
}

export function assignSnapgroups(postData) {
  return axiosInstance.put(SNAP_GROUP, postData);
}

import React, { useRef, useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import AWS from "aws-sdk";
import {
  uploadLogoApi,
  UploadEmployeeRotaApi,
} from "../../services/Hotels/HotelsService";
import { notifyTopRight, notifyError } from "../common/Toaster";
import S3Image from "../components/S3Image";

export default function ManageLogo({
  show,
  title,
  path,
  onHide,
  hotelData,
  refresh,
}) {
  const s3 = new AWS.S3();
  const [selectedImage, setSelectedImage] = useState(null);
  const [selectImage, setSelectImage] = useState(null);
  const [loader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false);

  const [empRota, setEmpRota] = useState(null);
  const fileInputRef = useRef(null);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    setEmpRota(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };
  function closeImage() {
    setSelectedImage("");
    setEmpRota("");
  }
  useEffect(() => {
    const handleUpload = async () => {
      if (!empRota) {
        console.error("No file selected");
        return;
      }

      const params = {
        Bucket: process.env.REACT_APP_BUCKET_NAME,
        Key: empRota?.name,
        Body: empRota,
      };
      setLoading(true);
      try {
        const data = await s3.upload(params).promise();
        setSelectImage(data);
      } catch (error) {
        console.error("Upload failed:", error);
      } finally {
        setLoading(false);
      }
    };

    handleUpload();
  }, [empRota]);

  async function handleUploadFile() {
    setLoader(true);
    if (title === "Manage  hotel logo") {
      try {
        const response = await uploadLogoApi(hotelData?._id, selectImage?.key);
        console.log(response);
        notifyTopRight("Upload file successfully");
        onHide();
        refresh();
      } catch (error) {
        notifyError(error?.response?.data?.message);
      } finally {
        setLoader(false);
      }
    } else {
      try {
        const response = await UploadEmployeeRotaApi(empRota);
        notifyTopRight("Upload file successfully");
        onHide();
      } catch (error) {
        notifyError(error?.response?.data?.message);
      } finally {
        setLoader(false);
      }
    }
  }
  // async function handleUploadRota() {
  //   setLoader(true);
  //   try {
  //     const response = await UploadEmployeeRotaApi(selectImage?.key);
  //     notifyTopRight("Upload file successfully");
  //     onHide();
  //   } catch (error) {
  //     notifyError(error?.response?.data?.message);
  //   } finally {
  //     setLoader(false);
  //   }
  // }

  return (
    <Modal className="modal fade" show={show} onHide={onHide} centered>
      <div className="" role="document">
        <div className="">
          <div className="modal-body">
            <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
            <div className="add-contact-box p-3">
              <h4 className="modal-heading">{title}</h4>
              <p className="error-text">{path}</p>
              <div className="mb-5">
                {empRota?.name?.length > 0 ? (
                  <p>SELECTED FILE</p>
                ) : (
                  <p style={{ color: "gray", padding: "8px" }}>
                    NO FILE SELECTED
                  </p>
                )}
                {title === "Upload staff rota" ? (
                  <div className="rota-container">
                    <input value={empRota?.name} contentEditable={false} />
                  </div>
                ) : (
                  <div className="logo-container">
                    {selectedImage?.length > 0 ? (
                      <img src={selectedImage} alt="" />
                    ) : (
                      <S3Image imageKey={hotelData?.logo} />
                    )}
                  </div>
                )}
                {title === "Upload staff rota" ? (
                  <input
                    type="file"
                    accept=".xlsx, .excel, .spreadsheetml, .xls"
                    onChange={handleImageChange}
                    style={{ display: "none" }}
                    ref={fileInputRef}
                  />
                ) : (
                  <div style={{ position: "relative" }}>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={handleImageChange}
                      style={{ display: "none" }}
                      ref={fileInputRef}
                    />
                    {empRota?.name?.length > 0 && (
                      <button
                        className="close-logo"
                        onClick={() => closeImage()}
                      >
                        X
                      </button>
                    )}
                  </div>
                )}
              </div>
              <div className="d-flex">
                <button
                  type="button"
                  className="btn theme-btn w-100 mr-2"
                  onClick={handleUploadButtonClick}
                >
                  {loading ? (
                    <Spinner animation="border" size="sm" />
                  ) : (
                    "UPLOAD"
                  )}
                </button>
                <button
                  type="submit"
                  className="btn theme-btn w-100"
                  onClick={handleUploadFile}
                >
                  {loader ? <Spinner animation="border" size="sm" /> : "Save"}{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

import React, { useEffect, useState } from "react";
import { Dropdown, Modal, Spinner, Table } from "react-bootstrap";
import { notifyError, notifyTopRight } from "../common/Toaster";
import {
  getLibraryCategory,
  getLibraryQuestions,
} from "../../services/QuestionLibrary/LibraryService";
import Switch from "react-switch";
import { assignQuestions } from "../../services/Category/CategoryService";
export default function AssignQuestion({
  show,
  path,
  table,
  onHide,
  id,
  categoryId,
  list,
}) {
  const [view, setView] = useState(true);
  const [loader, setLoader] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selected, setSelected] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [libraryCategory, setLibraryCategory] = useState("");

  console.log(list, "list ");

  let errorsObj = {
    name: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  const [questionStates, setQuestionStates] = useState([]);

  useEffect(() => {
    const initialQuestionStates = questions
      .filter((item) => item.assigned)
      .map((item) => item._id);

    setQuestionStates(initialQuestionStates);
  }, [questions]);
  useEffect(() => {
    const initialQuestionStates = list?.questions
      .filter((item) => item.assigned)
      .map((item) => item._id);

    setQuestionStates(initialQuestionStates);
  }, [questions]);
  const handlePreChange = (id, category) => {
    setLibraryCategory(category);
    setQuestionStates((prevQuestionStates) => {
      return prevQuestionStates.includes(id)
        ? prevQuestionStates.filter((checkedId) => checkedId !== id)
        : [...prevQuestionStates, id];
    });
  };

  const handleChange = (id) => {
    setQuestionStates((prevQuestionStates) => {
      return prevQuestionStates.includes(id)
        ? prevQuestionStates.filter((checkedId) => checkedId !== id)
        : [...prevQuestionStates, id];
    });
  };

  ////////  get category list   ///////////
  const getCategoryFun = async () => {
    // setLoader(true);
    try {
      const response = await getLibraryCategory(0, 0);
      console.log(response.data.data, "get response");
      setCategories(response.data.data);
      //   setLoader(false);
    } catch (error) {
      console.log(error, "get error ");
    } finally {
      //   setLoader(false);
    }
  };

  ////////  get question list   ///////////
  const getQuestionFun = async () => {
    setLoader(true);
    try {
      const response = await getLibraryQuestions(
        libraryCategory,
        0,
        0,
        categoryId
      );
      console.log(response);
      setQuestions(response.data.data);

      setLoader(false);
    } catch (error) {
      console.log(error, "get error ");
    } finally {
      setLoader(false);
    }
  };
  async function onSave(e) {
    e.preventDefault();
    setLoader(true);
    try {
      const response = await assignQuestions(
        categoryId,
        questionStates,
        libraryCategory
      );
      table(id);
      onHide();
      notifyTopRight(response.data.message);
    } catch (error) {
      notifyError(error?.response?.data?.message);
      console.log(error, "get error ");
    } finally {
      setLoader(false);
    }
  }
  useEffect(() => {
    getCategoryFun();
  }, [show]);

  useEffect(() => {
    getQuestionFun(id);
  }, [libraryCategory]);

  return (
    <Modal className="modal fade" show={show} onHide={onHide} centered>
      <div className="" role="document">
        <div className="">
          <form>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
              <h3 className="">Assign Questions</h3>
              <p>{path}</p>
              <div className="add-contact-box mt-2">
                <Dropdown onClick={() => setView(false)}>
                  <Dropdown.Toggle variant="" className="i-false outline-btn">
                    {/* {svg1} */} Select Library Category
                  </Dropdown.Toggle>
                  <Dropdown.Menu className="p-2  custom-scrollbar">
                    {categories?.map((item, i) => (
                      <Dropdown.Item
                        key={i}
                        className="my-dropdown-item"
                        onClick={() => {
                          getQuestionFun(item?._id);
                          setLibraryCategory(item?._id);
                        }}
                      >
                        {item?.name}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Menu>
                </Dropdown>

                <div className="mt-5">
                  <Table responsive>
                    <thead>
                      <tr>
                        <td>
                          <strong>Questions</strong>
                        </td>
                        <td>
                          <strong>Assign</strong>
                        </td>
                      </tr>
                    </thead>
                    {view ? (
                      <tbody>
                        {list?.questions?.map((item, i) => (
                          <tr key={item._id}>
                            <td
                              style={{ borderTop: "none" }}
                              className="border-bottom"
                            >
                              {item?.question}
                            </td>
                            <td
                              style={{ borderTop: "none" }}
                              className="border-bottom"
                            >
                              <label>
                                <Switch
                                  offColor="#6750A4"
                                  onColor="#21005D"
                                  width={36}
                                  height={20}
                                  onChange={() =>
                                    handlePreChange(
                                      item?._id,
                                      item?.category
                                    )
                                  }
                                  checked={questionStates.includes(item._id)}
                                  className="react-switch"
                                />
                              </label>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    ) : (
                      <>
                        {loader ? (
                          <div className="d-flex justify-content-center align-items-center">
                            {" "}
                            <Spinner animation="border" />
                          </div>
                        ) : (
                          <tbody>
                            {questions?.map((item, i) => (
                              <tr key={item._id}>
                                <td
                                  style={{ borderTop: "none" }}
                                  className="border-bottom"
                                >
                                  {item?.question}
                                </td>
                                <td
                                  style={{ borderTop: "none" }}
                                  className="border-bottom"
                                >
                                  <label>
                                    <Switch
                                      offColor="#6750A4"
                                      onColor="#21005D"
                                      width={36}
                                      height={20}
                                      onChange={() =>
                                        handleChange(item?._id, item?.assigned)
                                      }
                                      checked={questionStates.includes(
                                        item._id
                                      )}
                                      className="react-switch"
                                    />
                                  </label>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        )}
                      </>
                    )}
                  </Table>

                  <div className="mt-3 text-right w-100">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={onSave}
                    >
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}

import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { addThankyouApi } from "../../services/Hotels/HotelsService";
import { notifyError, notifyTopRight } from "../common/Toaster";

export default function ThankyouModal({
  show,
  onHide,
  title,
  hotelData,
  refresh,
}) {
  const [formData, setFormData] = useState({
    h1: hotelData?.submitPage?.h1,
    h2: hotelData?.submitPage?.h2,
    h3: hotelData?.submitPage?.h3,
    headline: hotelData?.submitPage?.headline,
    hotelId: hotelData?._id,
  });
  const [loader, setLoader] = useState(false);

  async function handleSubmitForm() {
    setLoader(true);
    try {
      const response = await addThankyouApi(formData);
      notifyTopRight("Updated Successfully");
      onHide();
      refresh();
    } catch (err) {
      notifyError(err?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  }

  return (
    <Modal className="modal fade" show={show} onHide={onHide} centered>
      <div className="" role="document">
        <div className="">
          <div className="modal-body">
            <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
            <div className="add-contact-box p-3">
              <h4 className="modal-heading">{title}</h4>
              <label className="mt-3" style={{ fontWeight: "500" }}>
                Headline{" "}
              </label>
              <div className="mb-2 form-group">
                <input
                  type="text"
                  value={formData.headline}
                  rows={5}
                  className="survey-form"
                  placeholder="Your feedback makes our hotel better."
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      headline: e.target.value,
                    })
                  }
                />
              </div>
              <label style={{ fontWeight: "500" }}>H1 </label>
              <div className="mb-2 form-group">
                <input
                  type="text"
                  value={formData?.h1}
                  rows={5}
                  className="survey-form"
                  placeholder="Your feedback makes our hotel better."
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      h1: e.target.value,
                    })
                  }
                />
              </div>
              <div className="mb-2 form-group">
                <label style={{ fontWeight: "600" }}>H2</label>
                <input
                  type="text"
                  value={formData?.h2}
                  rows={5}
                  className="survey-form"
                  placeholder="Your feedback makes our hotel better."
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      h2: e.target.value,
                    })
                  }
                />
              </div>
              <div className="mb-5 form-group">
                <label style={{ fontWeight: "500" }}>H3</label>

                <input
                  type="text"
                  value={formData.h3}
                  rows={5}
                  className="survey-form"
                  placeholder="Your feedback has donated 5p to the charity."
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      h3: e.target.value,
                    })
                  }
                />
              </div>
              <div className="">
                <button
                  type="submit"
                  className="btn theme-btn w-100"
                  onClick={handleSubmitForm}
                >
                  {loader ? (
                    <Spinner
                      animation="border"
                      size="sm
                  "
                    />
                  ) : (
                    "Save"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

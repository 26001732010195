import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { notifyError, notifyTopRight } from "../common/Toaster";

export default function CreateProfile({
  show,
  title,
  table,
  onHide,
  id,
  apiFunc,
}) {
  const [name, setName] = useState("");
  const [loader, setLoader] = useState(false);
  let errorsObj = {
    name: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  async function onSubmit(e) {
    e.preventDefault();

    let error = false;
    const errorObj = { ...errorsObj };
    if (name === "") {
      errorObj.name = "Name is Required !";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }
    setLoader(true);

    apiFunc(name, id)
      .then((response) => {
        setLoader(false);
        notifyTopRight("Profile Added Successfully");
        setName("");
        onHide();
        table();
      })
      .catch((error) => {
        setLoader(false);
        notifyError(error?.response?.data?.message);
      });
  }
  return (
    <Modal className="modal fade" show={show} onHide={onHide} centered>
      <div className="" role="document">
        <div className="">
          <form onSubmit={onSubmit}>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
              <div className="add-contact-box">
                <div className="add-contact-content">
                  <div className="form-group mb-3">
                    <label className="text-black font-w500">{title}</label>
                    <div className="contact-name">
                      <input
                        type="text"
                        className="form-input"
                        // name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        // placeholder="Enter your old password"
                      />
                      <span className="validation-text"></span>
                      {errors.name && (
                        <div className="text-danger fs-12">{errors.name}</div>
                      )}
                    </div>
                  </div>
                  <div className="">
                    <button
                      type="submit"
                      className="btn theme-btn w-100"
                      // onClick={onChangePassword}
                    >
                      CREATE
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}

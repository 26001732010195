import React, { useEffect, useState } from "react";
import { RangeDatePicker } from "@y0c/react-datepicker";
import { Card, Dropdown, Table, Spinner } from "react-bootstrap";
import uploadIcon from "../../images/svg/Iconography - Caesarzkn (3).svg";
import starIcon from "../../images/svg/Iconography - Caesarzkn.svg";
import editIcon from "../../images/svg/edit.svg";
import plusIcon from "../../images/svg/plusIcon.svg";
import CreateProfile from "../modal/CreateProfile";
import moment from "moment";

import {
  createHotel,
  getHotels,
  hotelLogin,
  getHotelLogin,
  deleteHotelLogin,
  getHeadOfficeLogin,
  deleteHeadOffice,
} from "../../services/Hotels/HotelsService";
import AddCategory from "../modal/AddCategory";
import {
  createCategory,
  getCategory,
} from "../../services/Category/CategoryService";
import ManageLogin from "../modal/ManageLogin";
import { profileLogin } from "../../services/Profiles/ProfileService";
import ManageLogo from "../modal/ManageLogo";
import {
  createSnapGroups,
  getSnapGroups,
} from "../../services/SnapGroups/SnapGroupService";
import { getDashboard } from "../../services/DashBoard/Dashboard";
import { createAreas, getAreas } from "../../services/Areas/AreaService";
import AssignQuestion from "../modal/AssignQuestion";
import AssignAreas from "../modal/AssignAreas";
import ErrorModal from "../modal/ErrorModal";
import MessageModal from "../modal/MessageModal";
import PrivacyStatement from "../modal/PrivacyStatement";
import EditPlaceholder from "../modal/EditPlaceholder";
import { notifyError, notifyTopRight } from "../common/Toaster";
import EditDailyGest from "../modal/EditDailyGest";
import ThankyouModal from "../modal/ThankyouModal";
import ColorRangeModal from "../modal/ColorRangeModal";
import NotificationTitleModal from "../modal/NotificationTitleModal";
export default function Profile(props) {
  const propsData = props.location.state;
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [loader, setLoader] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [categoryList, setCategoryList] = useState([]);
  const [showTable, setShowTable] = useState("");
  const [categoryModal, setCategoryModal] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [showHotelLogin, setShowHotelLogin] = useState(false);
  const [showManageLogoModal, setShowManageLogoModal] = useState(false);
  const [oneData, setOneData] = useState({});
  const [areaModal, setAreaModal] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [dashboardData, setDashboardData] = useState([]);
  const [areas, setAreas] = useState([]);
  const [selectedListCategory, setSelectedListCategory] = useState();
  const [snapGroup, setSnapGroups] = useState([]);
  const [assignQuestion, setAssignQuestion] = useState(false);
  const [dropdownCategory, setDropDownCategory] = useState("");
  const [assignArea, setAssignArea] = useState(false);
  const [snapGroupId, setSnapGroupId] = useState("");
  const [errorModal, setErrorModal] = useState(false);
  const [editDailyGest, setEditDailyGest] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [messageModal, setMessageModal] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [privacyStatementModal, setPrivacyStatementModal] = useState(false);
  const [thankyouModal, setThankyouModal] = useState(false);
  const [serveCommentsModal, setServeCommentsModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedGest, setSelectedGest] = useState();
  const [loginList, setLoginList] = useState([]);
  const [categoryLoader, setCategoryLoader] = useState(false);
  const [employeeRotation, setEmployeeRotation] = useState(false);
  const [colorRangeModal, setColorRangeModal] = useState(false);
  const [editSnapAlart, setEditSnapAlart] = useState(false);
  const [notificationTitleModal, setNotificationTitleModal] = useState(false);
  const [snapsAreas, setSnapsAreas] = useState(false);

  function handleFetch() {
    setLoader(true);
    getHotels(propsData?.item?._id, startDate, endDate)
      .then((response) => {
        setData(response?.data?.data);
        setLoader(false);
        handleShowTable();
        setSnapsAreas(false);
      })
      .catch((error) => {
        console.log(error.response, "helooooooooo");
        setLoader(false);
      });
  }

  function onDateChange(...args) {
    setStartDate(moment(args[0]).format("YYYY-MM-DD"));
    setEndDate(moment(args[1]).format("YYYY-MM-DD"));
  }

  function handleFetchDashboard() {
    setLoader(true);
    getDashboard(startDate, endDate)
      .then((response) => {
        setDashboardData(response?.data?.data);
        setLoader(false);
        handleFetch();
      })
      .catch((error) => {
        console.log(error.response, "helooooooooo");
        setLoader(false);
      });
  }

  function handleShowTable(item) {
    setSelectedItem(item);
    setShowTable(item?.name);
    getCategoryFun(item?._id);
  }
  ////////  get category list   ///////////
  const getCategoryFun = async (id) => {
    setCategoryLoader(true);
    if (!id) {
      return;
    }
    try {
      const response = await getCategory(id, startDate, endDate);
      setCategoryList(response?.data?.data);
      setCategoryLoader(false);
    } catch (error) {
      console.log(error, "get users error ");
      setCategoryLoader(false);
    }
  };
  ////////  get area list   ///////////
  async function handleFetchArea(id) {
    // setLoader(true);
    try {
      const response = await getAreas(id, startDate, endDate);
      setAreas(response?.data?.data);
    } catch (error) {
      console.log(error, "get users error ");
    }
  }
  ////////  get snapgroups list   ///////////
  const handleFetchGroups = async (id) => {
    // setLoader(true);
    try {
      const response = await getSnapGroups(id, startDate, endDate);
      setSnapGroups(response?.data?.data);
    } catch (error) {
      console.log(error, "get users error ");
    }
  };

  function handleShowModal(item) {
    setOneData(item);
    setAreaModal(true);
  }
  function handleShowTable2(item) {
    setSelectedCategory(item);
    handleFetchArea(item._id);
    handleFetchGroups(item._id);
    setSnapsAreas(true);
  }

  //=======================Login-Hotel-List============================================================
  async function handleLoginHotelList() {
    setLoading(true);
    try {
      const response = await getHotelLogin(selectedItem?._id);
      setLoginList(response?.data?.data);
      setLoading(false);
    } catch (error) {
      console.log(error, "get users error ");
      setLoading(false);
    }
  }
  //=======================Delete-Hotel-Login============================================================
  async function handleDeleteLoginHotel(id) {
    setLoading(true);
    try {
      const response = await deleteHotelLogin(id);
      if (response.status == 200) {
        setLoading(false);
        handleLoginHotelList();
        notifyTopRight("Deleted Successfully");
      }
    } catch (error) {
      notifyError(error?.response?.data?.message);
      setLoading(false);
    }
  }
  //////// ==================== creat snapgroups list ================================  ///////////
  const onCreatGroup = async (categoryId, hotelId) => {
    setLoading(true);
    // setLoader(true);
    try {
      const response = await createSnapGroups(categoryId, hotelId);
      handleFetchGroups(categoryId);
      setMessageModal(true);
      setLoading(false);
      setSuccessMessage("New Group has been created");
    } catch (error) {
      console.log(error, "get users error ");
      setLoading(false);
    }
  };
  //==================================headOfficeLoginApis==================================
  async function handleLoginHeadOfficeList() {
    setLoading(true);
    try {
      const response = await getHeadOfficeLogin();
      setLoginList(response?.data?.data);
      setLoading(false);
    } catch (error) {
      console.log(error, "get users error ");
      setLoading(false);
    }
  }

  async function handleDeleteHeadOffice(id) {
    setLoading(true);
    try {
      const response = await deleteHeadOffice(id);
      if (response.status == 200) {
        setLoading(false);
        handleLoginHeadOfficeList();
        notifyTopRight("Deleted Successfully");
      }
    } catch (error) {
      notifyError(error?.response?.data?.message);
      setLoading(false);
    }
  }
  useEffect(() => {
    if (startDate && endDate !== undefined) {
      handleFetchDashboard();
    }
  }, [startDate, endDate]);

  //================================================================
  useEffect(() => {
    handleFetch();
  }, [propsData?.item?._id, endDate]);

  useEffect(() => {
    if (dropdownCategory) {
      handleFetchArea(dropdownCategory);
    }
  }, [dropdownCategory, areas?.length]);
  useEffect(() => {
    if (selectedCategory?._id) {
      handleFetchArea();
    }
  }, [selectedCategory?._id]);
  return (
    <div>
      <div className="d-flex mb-5">
        <div className="ml-4">
          <RangeDatePicker
            startPlaceholder="From"
            endPlaceholder="To"
            onChange={onDateChange}
          />
        </div>
        <div className="small-card ml-4">
          <img src={starIcon} alt="bagIcon" />
          <div>
            <p className="light-text">Total Snap</p>
            <h3>{dashboardData?.count}</h3>
          </div>
        </div>
      </div>
      <Card>
        <Card.Header className="d-block">
          <h3>Profile: {propsData?.item?.name}</h3>
          <button
            type="button"
            className="outline-btn mt-3"
            onClick={() => setShowModal(true)}
          >
            Add hotel
          </button>
          <button
            type="button"
            className="outline-btn mt-3 ml-2"
            onClick={() => setShowLogin(true)}
          >
            <img src={uploadIcon} className="mr-1" />
            Head office logins
          </button>
        </Card.Header>
        <Card.Body>
          {loader ? (
            <div className="d-flex justify-content-center">
              <Spinner animation="border" />
            </div>
          ) : (
            <Table responsive>
              <thead>
                <tr className="text-center">
                  <th>
                    <strong>Hotel</strong>
                  </th>
                  <th>
                    <strong>Categories</strong>
                  </th>
                  <th>
                    <strong>Daily guests</strong>
                  </th>
                  <th>
                    <strong>Avg. daily SNAPs</strong>
                  </th>
                  <th>
                    <strong>SNAP ratio</strong>
                  </th>
                  <th>
                    <strong>SNAP score</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, i) => (
                  <tr
                    className={`text-center pointer ${
                      selectedItem === item ? "highlighted-row" : ""
                    }`}
                    key={i}
                  >
                    <td onClick={() => handleShowTable(item)}>{item?.name}</td>
                    <td onClick={() => handleShowTable(item)}>
                      {item?.categories?.length}
                    </td>
                    <td
                      onClick={() => {
                        setEditDailyGest(true);
                        setSelectedGest(item);
                      }}
                    >
                      {item?.dailyGuests}{" "}
                      <img src={editIcon} className="ml-2" />
                    </td>
                    <td onClick={() => handleShowTable(item)}>
                      {item?.dailySnaps}
                    </td>
                    <td onClick={() => handleShowTable(item)}>
                      {item?.snapRatio}
                    </td>
                    <td onClick={() => handleShowTable(item)}>
                      {item?.snapScore}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Card.Body>
      </Card>
      {showTable && (
        <>
          <Card>
            <Card.Header className="d-block">
              <h3>Hotel overview</h3>
              <p>
                {propsData?.item?.name}
                {">"} {showTable}
              </p>
              <button
                type="button"
                className="outline-btn mt-3"
                onClick={() => setCategoryModal(true)}
              >
                Create category
              </button>
              <button
                type="button"
                className="outline-btn mt-3 ml-2"
                onClick={() => setShowHotelLogin(true)}
              >
                <img src={uploadIcon} className="mr-1" />
                Hotel logins
              </button>
              <button
                type="button"
                className="outline-btn mt-3 ml-2"
                onClick={() => setShowManageLogoModal(true)}
              >
                <img src={uploadIcon} className="mr-1" />
                Manage logo
              </button>
              <button
                type="button"
                className="outline-btn mt-3 ml-2"
                onClick={() => setPrivacyStatementModal(true)}
              >
                <img src={editIcon} className="mr-1" />
                Survey privacy statement
              </button>
              <button
                type="button"
                className="outline-btn mt-3 ml-2"
                onClick={() => setColorRangeModal(true)}
              >
                <img src={uploadIcon} className="mr-1" />
                Colour ranges
              </button>
              <button
                type="button"
                className="outline-btn mt-3 ml-2"
                onClick={() => setEmployeeRotation(true)}
              >
                <img src={uploadIcon} className="mr-1" />
                Upload staff rota
              </button>
              <button
                type="button"
                className="outline-btn mt-3 ml-2"
                onClick={() => setServeCommentsModal(true)}
              >
                <img src={editIcon} className="mr-1" />
                Survey comment fields
              </button>
              <button
                type="button"
                className="outline-btn mt-3 ml-2"
                onClick={() => setThankyouModal(true)}
              >
                <img src={editIcon} className="mr-1" />
                Survey Thank You page
              </button>
              <button
                type="button"
                className="outline-btn mt-3 ml-2"
                onClick={() => setNotificationTitleModal(true)}
              >
                <img src={editIcon} className="mr-1" />
                Notifications title
              </button>
            </Card.Header>
            <Card.Body>
              {categoryLoader ? (
                <div className="d-flex justify-content-center">
                  <Spinner animation="border" />
                </div>
              ) : (
                <Table responsive>
                  <thead>
                    <tr className="text-center">
                      <th>
                        <strong>Categories</strong>
                      </th>
                      <th>
                        <strong>Areas</strong>
                      </th>
                      <th>
                        <strong>SNAP groups</strong>
                      </th>
                      <th>
                        <strong>Questions</strong>
                      </th>
                      <th>
                        <strong>Avg. daily SNAPs</strong>
                      </th>

                      <th>
                        <strong>SNAP ratio</strong>
                      </th>
                      <th>
                        <strong>SNAP score</strong>
                      </th>
                      <th>
                        <strong>SNAP point alert</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {categoryList?.map((item, i) => (
                      <tr
                        key={i}
                        className={`text-center pointer ${
                          selectedCategory === item ? "highlighted-row" : ""
                        }`}
                      >
                        <td onClick={() => handleShowTable2(item)}>
                          {item?.name}
                        </td>
                        <td onClick={() => handleShowTable2(item)}>
                          {item?.areas?.length}{" "}
                          <img
                            src={plusIcon}
                            className="ml-2 pointer"
                            onClick={() => handleShowModal(item)}
                          />
                        </td>
                        <td onClick={() => handleShowTable2(item)}>
                          {item?.snapGroups?.length}{" "}
                        </td>
                        <td onClick={() => handleShowTable2(item)}>
                          {item?.questions?.length}{" "}
                          <img
                            src={plusIcon}
                            className="ml-2 pointer"
                            onClick={() => {
                              setAssignQuestion(true);
                              setSelectedListCategory(item);
                            }}
                          />
                        </td>
                        <td onClick={() => handleShowTable2(item)}>
                          {item?.dailySnaps}
                        </td>
                        <td onClick={() => handleShowTable2(item)}>
                          {item?.snapRatio}
                        </td>
                        <td onClick={() => handleShowTable2(item)}>
                          {item?.snapScore}
                        </td>
                        <td
                          onClick={() => {
                            setEditSnapAlart(true);
                            setSelectedListCategory(item);
                          }}
                        >
                          {item?.snapPointAlert}{" "}
                          <img src={editIcon} className="ml-2" />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              )}
            </Card.Body>
          </Card>
        </>
      )}
      {snapsAreas && (
        <div className="d-flex justify-content-between" style={{ gap: "2rem" }}>
          <Card className="w-50">
            <Card.Header className="d-block">
              <h3>SNAP groups: {showTable}</h3>
              <button
                type="button"
                className="outline-btn mt-3"
                onClick={() =>
                  onCreatGroup(selectedCategory?._id, selectedItem?._id)
                }
              >
                {loading ? (
                  <Spinner animation="border" size="sm" />
                ) : (
                  "Create SNAP group"
                )}
              </button>
            </Card.Header>
            <Card.Body className="pt-0">
              <div className="custom-scrollbar">
                <Table responsive>
                  <thead>
                    <tr className="text-center">
                      <th>
                        <strong>Group</strong>
                      </th>
                      <th>
                        <strong>Assigned Areas</strong>
                      </th>
                      <th>
                        <strong>Avg. daily SNAPs</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {snapGroup?.map((item, i) => (
                      <tr className="text-center">
                        <td>{item?.name}</td>
                        <td>
                          {item?.areas?.length}{" "}
                          <img
                            src={plusIcon}
                            className="ml-2 pointer"
                            onClick={() => {
                              setAssignArea(true);
                              setSnapGroupId(item);
                            }}
                          />
                        </td>
                        <td>{item?.dailySnaps}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
          <Card className="w-50">
            <Card.Header className="d-block">
              <h3>Areas: {showTable}</h3>
              <Dropdown>
                <Dropdown.Toggle variant="" className="i-false outline-btn">
                  {/* {svg1} */} Select Category
                </Dropdown.Toggle>
                <Dropdown.Menu className="p-2">
                  {categoryList?.map((item, i) => (
                    <Dropdown.Item
                      key={i}
                      className="my-dropdown-item"
                      onClick={() => setDropDownCategory(item?._id)}
                    >
                      {item?.name}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </Card.Header>
            <Card.Body className="pt-0">
              <div className="custom-scrollbar">
                <Table responsive>
                  <thead>
                    <tr className="text-center">
                      <th>
                        <strong>Area</strong>
                      </th>
                      <th>
                        <strong>Avg. daily SNAPs</strong>
                      </th>
                      <th>
                        <strong>SNAP score</strong>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {areas?.map((item, i) => (
                      <tr className="text-center">
                        <td>{item?.name}</td>
                        <td>{item?.dailySnaps}</td>
                        <td>{item?.snapScore}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Card.Body>
          </Card>
        </div>
      )}

      {showModal && (
        <CreateProfile
          show={showModal}
          title={"Create new Hotel"}
          table={handleFetch}
          onHide={() => setShowModal(false)}
          id={propsData?.item?._id}
          apiFunc={createHotel}
        />
      )}
      {categoryModal && (
        <AddCategory
          title={"Create Category"}
          title2={"Current Categories"}
          show={categoryModal}
          path={`${propsData?.item?.name} > ${showTable}`}
          table={getCategoryFun}
          onHide={() => setCategoryModal(false)}
          id={selectedItem?._id}
          hotelId={selectedItem?._id}
          apiFunc={createCategory}
          categoryList={categoryList}
          // getAreasFun={handleFetchArea}
        />
      )}
      {showLogin && (
        <ManageLogin
          title="Manage  profile logins"
          path={propsData?.item?.name}
          show={showLogin}
          onHide={() => setShowLogin(false)}
          apiFunc={profileLogin}
          getHotelList={handleLoginHeadOfficeList}
          loginList={loginList}
          snipper={loading}
          deleteHotel={handleDeleteHeadOffice}
        />
      )}
      {showHotelLogin && (
        <ManageLogin
          title="Manage  hotel logins"
          path={propsData?.item?.name + ">" + showTable}
          show={showHotelLogin}
          hotelId={selectedItem?._id}
          onHide={() => setShowHotelLogin(false)}
          apiFunc={hotelLogin}
          getHotelList={handleLoginHotelList}
          loginList={loginList}
          snipper={loading}
          deleteHotel={handleDeleteLoginHotel}
        />
      )}
      {showManageLogoModal && (
        <ManageLogo
          title="Manage  hotel logo"
          path={propsData?.item?.name + " > " + showTable}
          show={showManageLogoModal}
          hotelData={selectedItem}
          onHide={() => setShowManageLogoModal(false)}
          apiFunc={hotelLogin}
          loader={loader}
          refresh={handleFetch}
        />
      )}
      {employeeRotation && (
        <ManageLogo
          title="Upload staff rota"
          path={propsData?.item?.name + " > " + showTable}
          show={employeeRotation}
          hotelId={selectedItem?._id}
          onHide={() => setEmployeeRotation(false)}
          apiFunc={hotelLogin}
          loader={loader}
        />
      )}
      {areaModal && (
        <AddCategory
          title={"Create Area"}
          title2={"Current Areas"}
          show={areaModal}
          path={`${propsData?.item?.name} > ${showTable} > ${oneData?.name}`}
          id={selectedCategory?._id}
          table={getCategoryFun}
          onHide={() => setAreaModal(false)}
          hotelId={selectedItem?._id}
          apiFunc={createAreas}
          categoryList={areas}
          getAreasFun={handleFetchArea}
        />
      )}
      {colorRangeModal && (
        <ColorRangeModal
          path={propsData?.item?.name + " > " + showTable}
          show={colorRangeModal}
          onHide={() => setColorRangeModal(false)}
          data={selectedItem}
          refresh={handleFetch}
        />
      )}

      {assignQuestion && (
        <AssignQuestion
          path={`${propsData?.item?.name} > ${showTable} > ${selectedCategory?.name}`}
          show={assignQuestion}
          table={getCategoryFun}
          id={selectedItem._id}
          categoryId={selectedCategory?._id}
          onHide={() => setAssignQuestion(false)}
          list={selectedListCategory}
        />
      )}

      {assignArea && (
        <AssignAreas
          path={`${propsData?.item?.name} > ${showTable} > ${selectedCategory?.name}`}
          qrcodePath={`https://survey.microsurvey.uk/?sId=${snapGroupId?._id}&hId=${selectedItem?._id}`}
          show={assignArea}
          table={handleFetchGroups}
          category={selectedCategory}
          snapGroupId={snapGroupId?._id}
          setErrorMessage={setErrorMessage}
          setErrorModal={setErrorModal}
          setMessageModal={setMessageModal}
          setSuccessMessage={setSuccessMessage}
          onHide={() => setAssignArea(false)}
          list={snapGroup}
        />
      )}

      {errorModal && (
        <ErrorModal
          show={errorModal}
          errorMessage={errorMessage}
          onHide={() => setErrorModal(false)}
        />
      )}
      {messageModal && (
        <MessageModal
          show={messageModal}
          successMessage={successMessage}
          onHide={() => setMessageModal(false)}
        />
      )}
      {privacyStatementModal && (
        <PrivacyStatement
          path={`${propsData?.item?.name} > ${showTable}`}
          show={privacyStatementModal}
          onHide={() => setPrivacyStatementModal(false)}
          hotelData={selectedItem}
          refresh={handleFetch}
        />
      )}
      {thankyouModal && (
        <ThankyouModal
          title={"Edit survey Thank You page"}
          show={thankyouModal}
          hotelData={selectedItem}
          onHide={() => setThankyouModal(false)}
          refresh={handleFetch}
        />
      )}
      {serveCommentsModal && (
        <EditPlaceholder
          title={"Edit survey comment fields"}
          show={serveCommentsModal}
          onHide={() => setServeCommentsModal(false)}
          hotelData={selectedItem}
          refresh={handleFetch}
        />
      )}
      {editDailyGest && data && (
        <EditDailyGest
          title={"Update daily Guests"}
          show={editDailyGest}
          onHide={() => setEditDailyGest(false)}
          data={selectedGest}
          refresh={handleFetch}
        />
      )}
      {editSnapAlart && data && (
        <EditDailyGest
          title={"Edit SnapPoint Alert"}
          show={editSnapAlart}
          onHide={() => setEditSnapAlart(false)}
          data={selectedListCategory}
          refresh={handleFetch}
        />
      )}
      {notificationTitleModal && (
        <NotificationTitleModal
          hotelId={selectedItem?._id}
          show={notificationTitleModal}
          hotelData={selectedItem}
          onHide={() => setNotificationTitleModal(false)}
        />
      )}
    </div>
  );
}

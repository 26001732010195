import { ASSIGN_QUESTIONS, CATEGORIES } from "../AuthApiEndPoints";
import axiosInstance from "../AxiosInstance";

export function getCategory(id, startDate, endDate) {
  return axiosInstance.get(
    CATEGORIES + `?hotelId=${id}&startDate=${startDate}&endDate=${endDate}`
  );
}

export function createCategory(Name, id) {
  const postData = {
    hotelId: id,
    name: Name,
  };
  return axiosInstance.post(CATEGORIES, postData);
}

export function assignQuestions(id, selectedIds, libraryCategory) {
  const postData = {
    categoryId: id,
    questionIds: selectedIds,
    libraryCategoryId: libraryCategory,
  };
  return axiosInstance.put(ASSIGN_QUESTIONS, postData);
}
export function colorRangeApi(id, greenColor, redColor, yellowColor) {
  const postData = {
    hotelId: id,
    green: greenColor,
    red: redColor,
    yellow: yellowColor,
  };
  return axiosInstance.put("admin/profile/colorRange", postData);
}

import React, { useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import {
  loadingToggleAction,
  loginAction,
} from "../../store/actions/AuthActions";

import "../css/pages/Login.css";
import { login, saveTokenInLocalStorage } from "../../services/AuthService";
import logo from "../../images/svg/Group-65.svg";

function Login(props) {
  const [email, setEmail] = useState("");
  let errorsObj = { email: "", password: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [password, setPassword] = useState("");
  const [apiError, setApiError] = useState();
  const dispatch = useDispatch();
  function onLogin(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (email === "") {
      errorObj.email = "Email is Required";
      error = true;
    }
    if (password === "") {
      errorObj.password = "Password is Required";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    dispatch(loadingToggleAction(true));
    dispatch(loginAction(email, password, props.history));
  }

  return (
    <div className="login-form-bx h-100">
      <div className="d-flex justify-content-center align-items-center h-100">
        <div className="login-form">
          <div className="mb-4 text-center">
            <img src={logo}/>
            <h3 className="mt-2 mb-1 font-w600">Admin Panel Login</h3>
            <p className="">For Biteluxe admin only</p>
          </div>
          {apiError && (
            <div
              role="alert"
              className="fade alert-dismissible fade show alert alert-danger show"
            >
              {apiError}
            </div>
          )}

          <form onSubmit={onLogin}>
            <div className="form-group">
              <label className="mb-2 ">
                <strong className="">Email</strong>
              </label>
              <input
                type="email"
                className="form-control mb-4"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
              />
              {errors.email && (
                <div className="text-danger fs-12">{errors.email}</div>
              )}
            </div>
            <div className="form-group">
              <label className="mb-2 ">
                <strong className="">Password</strong>
              </label>
              <input
                type="password"
                className="form-control"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your Password"
              />
              {errors.password && (
                <div className="text-danger fs-12">{errors.password}</div>
              )}
            </div>
            <div className="form-row d-flex justify-content-between mt-4 mb-2">
              <div className="form-group">
                <div className="custom-control custom-checkbox ml-1 ">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="basic_checkbox_1"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="basic_checkbox_1"
                  >
                    Remember me
                  </label>
                </div>
              </div>
            </div>
            <div className="text-center w-100 mt-5">
              <button
                type="submit"
                className="btn-login"
                onClick={() => props.history.push("/dashboard")}
              >
                Login
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    errorMessage: state.auth.errorMessage,
    successMessage: state.auth.successMessage,
    showLoading: state.auth.showLoading,
  };
};
export default connect(mapStateToProps)(Login);

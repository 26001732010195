import { LIBRARY, LIBRARY_QUESTION } from "../AuthApiEndPoints";
import axiosInstance from "../AxiosInstance";

export function getLibraryCategory(currentPage, limit) {
  return axiosInstance.get(LIBRARY + `?page=${currentPage}&limit=${limit}`);
}

export function createLibraryCategory(Name) {
  const postData = {
    name: Name,
  };
  return axiosInstance.post(LIBRARY, postData);
}

export function getLibraryQuestions(cId, currentPage, limit, id) {
  return axiosInstance.get(
    LIBRARY_QUESTION +
      `?categoryId=${cId}&page=${currentPage}&limit=${limit}&cId=${id}`
  );
}

export function createLibraryQuestions(question, categoryId) {
  const postData = {
    question,
    categoryId,
  };
  return axiosInstance.post(LIBRARY_QUESTION, postData);
}

export function editLibraryCategory(name, categoryId) {
  const postData = {
    name,
    categoryId,
  };
  return axiosInstance.put("admin/profile/libraryCategory", postData);
}

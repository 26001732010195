import React from "react";
// import { Loader, Placeholder } from 'rsuite';
import Lottie from "lottie-react";
import animationData from "../../images/Animation - 1702550361977.json";

export default function Spinner() {
  return (
    <div>
      <Lottie
        animationData={animationData}
        style={{ height: "200px", marginTop: "45px", padding: "30px" }}
      />
    </div>
  );
}

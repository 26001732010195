/// auth service

export const LOGIN = "admin/auth/login";

export const CHANGE_PASSWORD = "admin/auth/changePassword";

export const LOGOUT = "admin/auth/logout";

export const DASHBOARD = "admin/auth/dashboard";

export const PROFILE = "admin/profile/";

export const HOTEL = "admin/profile/hotels";

export const CATEGORIES = "admin/profile/categories";

export const ASSIGN_QUESTIONS = "admin/profile/assignQuestions";

export const PROFILE_LOGIN = "admin/profile/headOfficeLogin";

export const HOTEL_LOGIN = "admin/profile/hotelLogin";

export const LIBRARY = "admin/profile/libraryCategory";

export const LIBRARY_QUESTION = "admin/profile/questions";

export const AREAS = "admin/profile/areas";

export const SNAP_GROUP = "admin/profile/snapGroup";
export const HOME_DASHBOARD = "admin/profile/dashboard";

import { PROFILE, PROFILE_LOGIN } from "../AuthApiEndPoints";
import axiosInstance from "../AxiosInstance";

export function getProfile(currentPage, limit, search) {
  return axiosInstance.get(PROFILE);
}

export function createProfile(Name) {
  const postData = {
    name: Name,
  };
  return axiosInstance.post(PROFILE, postData);
}

export function profileLogin(email, password) {
  const postData = {
    email,
    password,
  };
  return axiosInstance.post(PROFILE_LOGIN, postData);
}

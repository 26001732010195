import React, { useEffect, useRef, useState } from "react";
import { Modal, Table } from "react-bootstrap";
import Switch from "react-switch";
import CustomTimePicker from "../components/CustomTimePicker";
import { getAllAreas } from "../../services/Areas/AreaService";
import { notifyError } from "../common/Toaster";
import { assignSnapgroups } from "../../services/SnapGroups/SnapGroupService";
import moment from "moment";
import QRCode from "react-qr-code";
export default function AssignAreas({
  show,
  path,
  qrcodePath,
  table,
  onHide,
  category,
  snapGroupId,
  setErrorMessage,
  setErrorModal,
  setMessageModal,
  setSuccessMessage,
}) {
  const [loader, setLoader] = useState(false);
  const [questionStates, setQuestionStates] = useState({});
  const [areas, setAreas] = useState([]);
  const [startTimeValues, setStartTimeValues] = useState([]);
  const [endTimeValues, setEndTimeValues] = useState([]);
  const [qrPath, setQrPath] = useState("");

  const qrCodeRef = useRef(null);

  const handleDownload = () => {
    const svg = document.getElementById("QRCode");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.download = "QRCode";
      downloadLink.href = `${pngFile}`;
      downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;
  };

  useEffect(() => {
    // const initialQuestionStates = areas?.map((area) => ({
    //   area: area._id,
    //   start: area.start,
    //   end: area.end,
    // }));
    const initialQuestionStates = areas?.map((area) => ({
      area: area.assigned ? area._id : null,
      start: area.start,
      end: area.end,
    }));
    // .filter((item) => item.area !== null);

    const areasAssign = initialQuestionStates?.map((area) => area.area) || [];
    const start = initialQuestionStates?.map((area) => area.start) || [];
    const end = initialQuestionStates?.map((area) => area.end) || [];
    start.map((item, i) => {
      const parsedTime = moment(item, "hh:mm a");
      const hours = parsedTime.format("hh");
      const minutes = parsedTime.format("mm");
      const period = parsedTime.format("a");
      startTimeValues[i] = {
        hourTime: hours,
        mintsTime: minutes,
        selectedTab: period,
      };
    });
    end.map((item, i) => {
      const parsedTime = moment(item, "hh:mm a");
      const hours = parsedTime.format("hh");
      const minutes = parsedTime.format("mm");
      const period = parsedTime.format("a");
      endTimeValues[i] = {
        hourTime: hours,
        mintsTime: minutes,
        selectedTab: period,
      };
    });

    setQuestionStates(areasAssign);
  }, [areas]);

  const handleChange = (itemId, nextChecked) => {
    setQuestionStates((prevQuestionStates) => {
      return prevQuestionStates.includes(itemId)
        ? prevQuestionStates.filter((checkedId) => checkedId !== itemId)
        : [...prevQuestionStates, itemId];
    });
  };

  const updateArray = (index, newValues, array) => {
    const newArray = [...array];
    newArray[index] = { ...newArray[index], ...newValues };
    return newArray;
  };
  const updateArray2 = (index, newValues, array) => {
    const newArray = [...array];
    newArray[index] = { ...newArray[index], ...newValues };
    return newArray;
  };
  ////////  get area list   ///////////
  const handleFetchArea = async (id) => {
    // setLoader(true);
    try {
      const response = await getAllAreas(id, snapGroupId);
      setAreas(response.data.data);
    } catch (error) {
      console.log(error, "get users error ");
    }
  };

  async function onSubmit(e) {
    e.preventDefault();
    const hasMissingData = questionStates.some((itemId) => {
      const areaIndex = areas.findIndex((area) => area._id === itemId);
      return (
        areaIndex !== -1 &&
        (!startTimeValues[areaIndex] || !endTimeValues[areaIndex])
      );
    });

    if (hasMissingData) {
      notifyError("Please select start time, end time, and minutes.");
      return;
    }

    try {
      // Create an array to store the assigned areas
      const assignData = [];

      // Iterate through selectedIds and create an object for each selected area
      questionStates.forEach((itemId) => {
        const areaIndex = areas.findIndex((area) => area._id === itemId);

        if (areaIndex !== -1) {
          const area = areas[areaIndex];
          const start = `${startTimeValues[areaIndex]?.hourTime}:${startTimeValues[areaIndex]?.mintsTime} ${startTimeValues[areaIndex]?.selectedTab}`;
          const end = `${endTimeValues[areaIndex]?.hourTime}:${endTimeValues[areaIndex]?.mintsTime} ${endTimeValues[areaIndex]?.selectedTab}`;

          assignData.push({
            area: itemId,
            start: start,
            end: end,
          });
        }
      });

      // Prepare the final data object to send in the API
      const postData = {
        snapGroupId: snapGroupId,
        assign: assignData,
        qr: qrcodePath,
      };
      const response = await assignSnapgroups(postData);
      table(category?._id);
      setQrPath(qrcodePath);
      setMessageModal(true);
      setSuccessMessage("QR code has been generated");
    } catch (error) {
      // notifyError(error?.response?.data?.message);
      setErrorModal(true);
      setErrorMessage(error?.response?.data?.message);
      console.log(error, "get error ");
    } finally {
      setLoader(false);
    }
  }

  useEffect(() => {
    handleFetchArea(category?._id);
  }, [category?._id]);
  return (
    <Modal
      className="modal fade"
      size="xl"
      show={show}
      onHide={onHide}
      centered
    >
      <div className="" role="document">
        <div className="">
          <form>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
              <h3 className="">Assign Areas to SNAP group</h3>
              <p>{path}</p>
              <div ref={qrCodeRef} className="d-none">
                <QRCode id="QRCode" value={qrPath} />
              </div>
              <div className="d-flex justify-content-start mt-4 mb-3">
                <button
                  type="button"
                  className="btn thin-theme-btn"
                  disabled={questionStates.length < 1}
                  onClick={onSubmit}
                >
                  GENERATE QR CODE
                </button>
                <button
                  type="button"
                  className="btn thin-theme-btn ml-2"
                  disabled={!qrPath}
                  onClick={handleDownload}
                >
                  DOWNLOAD QR CODE
                </button>
              </div>
              <h4 className="mb-3">
                STATUS:{" "}
                {qrPath && (
                  <span className="text-success">QR CODE GENERATED</span>
                )}
              </h4>
              <h4>
                Auto-generated path:
                <br />
                {qrPath}
              </h4>
              <div className="mt-3">
                <Table>
                  <thead>
                    <tr>
                      <td>
                        <strong>Areas</strong>
                      </td>
                      <td>
                        <strong>Assign</strong>
                      </td>
                      <td>
                        <strong>Start</strong>
                      </td>
                      <td>
                        <strong></strong>
                      </td>
                      <td>
                        <strong>End</strong>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {areas?.map((item, i) => (
                      <tr key={i}>
                        <td
                          style={{ borderTop: "none" }}
                          className="border-bottom"
                        >
                          {item?.category?.name} {">"} {item?.name}
                        </td>
                        <td
                          style={{ borderTop: "none" }}
                          className="border-bottom"
                        >
                          <label>
                            <Switch
                              offColor="#6750A4"
                              onColor="#21005D"
                              width={36}
                              height={20}
                              onChange={(nextChecked) =>
                                handleChange(item?._id, nextChecked)
                              }
                              checked={questionStates.includes(item._id)}
                              className="react-switch"
                            />
                          </label>
                        </td>
                        <td
                          style={{ borderTop: "none" }}
                          className="border-bottom"
                        >
                          <CustomTimePicker
                            hourTime={
                              startTimeValues[i]?.hourTime ||
                              startTimeValues[i]?.hr
                            }
                            mintsTime={
                              startTimeValues[i]?.mintsTime ||
                              startTimeValues[i]?.min
                            }
                            selectedTab={startTimeValues[i]?.selectedTab || ""}
                            setHourTime={(value) =>
                              setStartTimeValues((prev) =>
                                updateArray(i, { hourTime: value }, prev)
                              )
                            }
                            setMintsTime={(value) =>
                              setStartTimeValues((prev) =>
                                updateArray(i, { mintsTime: value }, prev)
                              )
                            }
                            setSelectedTab={(value) =>
                              setStartTimeValues((prev) =>
                                updateArray(i, { selectedTab: value }, prev)
                              )
                            }
                            onChange={(newValues) =>
                              setStartTimeValues((prev) =>
                                updateArray(i, newValues, prev)
                              )
                            }
                          />
                        </td>
                        <td
                          style={{ borderTop: "none" }}
                          className="border-bottom"
                        >
                          <b>To</b>
                        </td>
                        <td
                          style={{ borderTop: "none" }}
                          className="border-bottom"
                        >
                          <CustomTimePicker
                            hourTime={
                              endTimeValues[i]?.hourTime || endTimeValues[i]?.hr
                            }
                            mintsTime={
                              endTimeValues[i]?.mintsTime ||
                              endTimeValues[i]?.min
                            }
                            selectedTab={endTimeValues[i]?.selectedTab || ""}
                            setHourTime={(value) =>
                              setEndTimeValues((prev) =>
                                updateArray2(i, { hourTime: value }, prev)
                              )
                            }
                            setMintsTime={(value) =>
                              setEndTimeValues((prev) =>
                                updateArray2(i, { mintsTime: value }, prev)
                              )
                            }
                            setSelectedTab={(value) =>
                              setEndTimeValues((prev) =>
                                updateArray2(i, { selectedTab: value }, prev)
                              )
                            }
                            onChange={(newValues) =>
                              setEndTimeValues((prev) =>
                                updateArray2(i, newValues, prev)
                              )
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
              {/* <div className="mt-3 text-right w-100">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={onSubmit}
                >
                  Save
                </button>
              </div> */}
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}

import axios from "axios";
import { loginConfirmedAction, logout } from "../store/actions/AuthActions";
import { CHANGE_PASSWORD, DASHBOARD, LOGIN, LOGOUT } from "./AuthApiEndPoints";
import axiosInstance from "./AxiosInstance";
import { notifyError } from "../jsx/common/Toaster";

export function signUp(email, password) {


  const postData = {
    email,
    password,
    returnSecureToken: true,
  };

  return axios.post(
    `https://identitytoolkit.googleapis.com/v1/accounts:signUp?key=AIzaSyD3RPAp3nuETDn9OQimqn_YF6zdzqWITII`,
    postData
  );
}

export function login(email, password) {
  const postData = {
    email,
    password,
  };

  return axiosInstance.post(LOGIN, postData);
}

export function dashboard(filter) {

  return axiosInstance.get(DASHBOARD +`?data=${filter}`);
}
export function changePasswoard(oldPassword, newPassword) {
    const postData = {
        oldPassword, newPassword
    };
  
    return axiosInstance.put(CHANGE_PASSWORD, postData);
  }
export function logOut() {

    return axiosInstance.post( LOGOUT,
        { test: "" },
      );
  }

export function formatError(errorResponse) {
  notifyError(errorResponse);
}

export function saveTokenInLocalStorage(tokenDetails) {
  localStorage.setItem("BiteLuxeDetails", tokenDetails);
}

export function runLogoutTimer(dispatch, timer, history) {
  setTimeout(() => {
    dispatch(logout(history));
  }, timer);
}

export function checkAutoLogin(dispatch, history) {
  const tokenDetailsString = localStorage.getItem("BiteLuxeDetails");
  // let tokenDetails = "";
  if (!tokenDetailsString) {
    history.push("/");
    dispatch(logout(history));
    return;
  }
  dispatch(loginConfirmedAction(tokenDetailsString));
}

import { HOME_DASHBOARD } from "../AuthApiEndPoints";
import axiosInstance from "../AxiosInstance";

export function getDashboard(startDate, endDate) {
  return axiosInstance.get(
    HOME_DASHBOARD + `?startDate=${startDate}&endDate=${endDate}`
  );
}

export function getCsv() {
  return axiosInstance.get("admin/profile/dashboard/csv", {
    responseType: "blob",
  });
}

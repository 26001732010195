import { HOTEL, HOTEL_LOGIN } from "../AuthApiEndPoints";
import axiosInstance from "../AxiosInstance";

export function getHotels(id, startDate, endDate) {
  // console.log(search, " search");
  return axiosInstance.get(
    HOTEL + `?profileId=${id}&startDate=${startDate}&endDate=${endDate}`
  );
}

export function createHotel(Name, id) {
  // console.log(search, " search");
  const postData = {
    profileId: id,
    name: Name,
  };
  return axiosInstance.post(HOTEL, postData);
}

export function hotelLogin(email, password, hotelId) {
  const postData = {
    email,
    password,
    hotelId,
  };
  return axiosInstance.post(HOTEL_LOGIN, postData);
}

export function uploadLogoApi(hotelId, image) {
  const postData = {
    hotelId,
    logo: image,
  };
  return axiosInstance.put("admin/profile/hotels", postData);
}
export function notificationTitleApi(title, hotelId) {
  const postData = {
    hotelId,
    title,
  };
  return axiosInstance.put("admin/profile/notificationAlert", postData);
}
export function UploadEmployeeRotaApi(doc) {
  const formData = new FormData();
  formData.append("doc", doc);

  return axiosInstance.post("admin/profile/staffRota", formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function addPrivacyStatementApi(text, heading, hotelId) {
  const postData = {
    text: text,
    heading: heading,
    hotelId: hotelId,
  };
  return axiosInstance.post("admin/profile/privacyStatement", postData);
}

export function addPlaceholderApiApi(postData) {
  return axiosInstance.post("admin/profile/surveyPlaceholders", postData);
}
export function addThankyouApi(postData) {
  return axiosInstance.post("admin/profile/surveySubmitPage", postData);
}
export function getHotelLogin(id) {
  return axiosInstance.get(`admin/profile/hotelLogin?hId=${id}`);
}
export function getHeadOfficeLogin() {
  return axiosInstance.get(`admin/profile/headOfficeLogin`);
}
export function editDailyGestApi(hotelId, dailyGuests) {
  const postData = {
    hotelId,
    dailyGuests,
  };
  return axiosInstance.put("admin/profile/dailyGuests", postData);
}
export function editSnapPointAlertApi(categoryId, snapPoint) {
  const postData = {
    categoryId,
    snapPoint,
  };
  return axiosInstance.put("admin/profile/snapPointAlert", postData);
}
export function deleteHotelLogin(id) {
  return axiosInstance.delete(`admin/profile/hotelLogin?id=${id}`);
}
export function deleteHeadOffice(id) {
  return axiosInstance.delete(`admin/profile/headOfficeLogin?id=${id}`);
}

import React, { useEffect, useState } from "react";
import { Card, Table } from "react-bootstrap";
import { RangeDatePicker } from "@y0c/react-datepicker";
import { Line } from "react-chartjs-2";

import moment from "moment";
// Images
import bagIcon from "../../../images/svg/ShoppingBagOpen (1).svg";
import CreateProfile from "../../modal/CreateProfile";
import {
  createProfile,
  getProfile,
} from "../../../services/Profiles/ProfileService";
import { getCsv, getDashboard } from "../../../services/DashBoard/Dashboard";
import { getHotels } from "../../../services/Hotels/HotelsService";
import Spinner from "../../common/Spinner";
// import { LineChart } from "@mui/x-charts/LineChart";

const Home = (props) => {
  const [loader, setLoader] = useState(false);
  const [loader2, setLoader2] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [hotelTable, setHotelTable] = useState([]);
  const [showTable, setShowTable] = useState("");
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [dashboardData, setDashboardData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const chartData = {
    labels: dashboardData?.xAxis,
    datasets: [
      {
        label: "Snap",
        data: dashboardData?.yAxis,
        fill: false,
        backgroundColor: "transparent",
        borderColor: "rgba(75,192,192,1)",
        marginLeft: "10px",
      },
    ],
  };

  function handleFetchDashboard() {
    setLoader(true);
    getDashboard(startDate, endDate)
      .then((response) => {
        setDashboardData(response.data.data);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error.response, "helooooooooo");
        setLoader(false);
      });
  }
  function handleFetchProfiles() {
    setLoader(true);
    getProfile()
      .then((response) => {
        setData(response.data.data);
        setLoader(false);
      })
      .catch((error) => {
        console.log(error.response, "helooooooooo");
        setLoader(false);
      });
  }

  useEffect(() => {
    const defaultStartDate = moment().format("YYYY-MM-DD");
    const defaultEndDate = moment().format("YYYY-MM-DD");

    setStartDate(defaultStartDate);
    setEndDate(defaultEndDate);
  }, []);
  function onDateChange(...args) {
    setStartDate(moment(args[0]).format("YYYY-MM-DD"));
    setEndDate(moment(args[1]).format("YYYY-MM-DD"));
  }

  async function handleDownloadCsv() {
    setLoading(true);
    try {
      const response = await getCsv();
      const blob = new Blob([response.data], { type: "text/csv" });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "Dashboard.xlsx";

      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  }

  function handleFetch(id) {
    setLoader2(true);
    getHotels(id)
      .then((response) => {
        setHotelTable(response.data.data);
        setLoader2(false);
      })
      .catch((error) => {
        console.log(error.response, "helooooooooo");
        setLoader2(false);
      });
  }
  function handleShowTable(item) {
    setSelectedItem(item);
    setShowTable(item.name);
    handleFetch(item._id);
  }
  useEffect(() => {
    if (startDate && endDate !== undefined) {
      handleFetchDashboard();
    }
    handleFetchProfiles();
  }, [startDate, endDate]);

  return (
    <>
      <div className="form-head d-flex mb-5 align-items-center ">
        <div className="add-btn" onClick={() => setShowModal(true)}>
          + NEW PROFILE
        </div>
        <div className="ml-4">
          <RangeDatePicker
            startPlaceholder="From"
            endPlaceholder="To"
            onChange={onDateChange}
          />
        </div>
        <div className="small-card ml-4">
          <img src={bagIcon} alt="bagIcon" />
          <div>
            <p className="light-text">Total Snap</p>
            <h3>{dashboardData?.count}</h3>
          </div>
        </div>
        <div className="p-3 d-flex justify-content-end">
          <button
            type="button"
            disabled={loader}
            className="btn btn-secondary btn"
            onClick={() => handleDownloadCsv()}
          >
            Download Csv
          </button>
        </div>
      </div>
      <Card>
        <Card.Header>
          <h3>All Profile</h3>
        </Card.Header>
        {loader ? <Spinner /> : <Line data={chartData} />}
      </Card>
      <Card>
        <Card.Header>
          <h3>Profiles</h3>
        </Card.Header>
        <Card.Body>
          {loader ? (
            <Spinner />
          ) : (
            <Table responsive>
              <thead>
                <tr className="text-center">
                  <th>
                    <strong>NAME</strong>
                  </th>
                  <th>
                    <strong>Hotels</strong>
                  </th>
                  <th>
                    <strong>Total questions</strong>
                  </th>
                  <th>
                    <strong>Daily guests</strong>
                  </th>
                  <th>
                    <strong>Avg. daily SNAPS</strong>
                  </th>
                  <th>
                    <strong>SNAP ratio</strong>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data?.map((item, i) => (
                  <tr
                    className={`text-center pointer ${
                      selectedItem === item ? "highlighted-row" : ""
                    }`}
                    key={i}
                    onClick={() => handleShowTable(item)}
                  >
                    <td>{item?.name}</td>
                    <td>{item?.hotels?.length}</td>
                    <td>{item?.totalQuestion}</td>

                    <td>{item?.dailyGuests}</td>
                    <td>{item?.dailySnaps}</td>
                    <td>{item?.snapRatio}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </Card.Body>
      </Card>
      {showTable && (
        <Card>
          <Card.Header>
            <h3>Hotels:{showTable}</h3>
          </Card.Header>
          <Card.Body>
            {loader2 ? (
              <Spinner />
            ) : (
              <Table responsive>
                <thead>
                  <tr className="text-center">
                    <th>
                      <strong>Hotel</strong>
                    </th>
                    <th>
                      <strong>SNAP groups</strong>
                    </th>
                    <th>
                      <strong>Categories</strong>
                    </th>
                    <th>
                      <strong>Questions per hotel</strong>
                    </th>
                    <th>
                      <strong>Daily guests</strong>
                    </th>
                    <th>
                      <strong>SNAP score</strong>
                    </th>
                    <th>
                      <strong>Avg. daily SNAPS</strong>
                    </th>
                    <th>
                      <strong>SNAP ratio</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {hotelTable.map((item, i) => (
                    <tr className="text-center">
                      <td>{item.name}</td>
                      <td>{item?.snapGroups?.length}</td>
                      <td>{item?.categories?.length}</td>

                      <td>{item?.totalQuestions}</td>
                      <td>{item?.dailyGuests}</td>
                      <td>{item?.snapScore}</td>
                      <td>{item?.dailySnaps}</td>
                      <td>{item?.snapRatio}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </Card.Body>
        </Card>
      )}

      {showModal && (
        <CreateProfile
          show={showModal}
          title={"Create new Profile"}
          table={handleFetchProfiles}
          onHide={() => setShowModal(false)}
          apiFunc={createProfile}
        />
      )}
    </>
  );
};

export default Home;

import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { notifyError, notifyTopRight } from "../common/Toaster";

export default function ManageLogin({
  title,
  show,
  path,
  hotelId,
  onHide,
  apiFunc,
  getHotelList,
  loginList,
  snipper,
  deleteHotel,
}) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loader, setLoader] = useState(false);
  let errorsObj = {
    email: "",
    password: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  async function onSubmit(e) {
    e.preventDefault();

    let error = false;
    const errorObj = { ...errorsObj };
    if (email === "") {
      errorObj.email = "Email is Required !";
      error = true;
    }
    if (password === "") {
      errorObj.password = "Password is Required !";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    setLoader(true);
    try {
      const response = await apiFunc(email, password, hotelId);
      console.log(response, "get response");
      setEmail("");
      setPassword("");
      onHide();
      notifyTopRight(response.data.message);
    } catch (error) {
      console.log(error.response);
      notifyError(error?.response?.data?.message);
      console.log(error, "get error ");
    } finally {
      setLoader(false);
    }
  }
  useEffect(() => {
    getHotelList();
  }, []);

  return (
    <Modal className="modal fade" show={show} onHide={onHide} centered>
      <div className="" role="document">
        <div className="">
          <form onSubmit={onSubmit}>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
              <h3 className="">{title}</h3>
              <p>{path}</p>
              <div className="add-contact-box">
                <div className="add-contact-content">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="form-group mt-3 mb-3">
                      <label className="text-black font-w500">Email</label>
                      <div className="contact-name">
                        <input
                          type="email"
                          className="form-input"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Enter email"
                        />
                        <span className="validation-text"></span>
                        {errors.email && (
                          <div className="text-danger fs-12">
                            {errors.email}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group mt-3 mb-3 ml-2">
                      <label className="text-black font-w500">Password</label>
                      <div className="contact-name">
                        <input
                          type="password"
                          className="form-input"
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          placeholder="Enter password"
                        />
                        <span className="validation-text"></span>
                        {errors.password && (
                          <div className="text-danger fs-12">
                            {errors.password}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="mt-3">
                    <button
                      type="submit"
                      className="btn theme-btn w-100"
                      // onClick={onChangePassword}
                    >
                      CREATE
                    </button>
                  </div>
                </div>
                <div>
                  <h3 className="mt-5">Current logins</h3>
                  {snipper ? (
                    <div className="d-flex justify-content-center">
                      {" "}
                      <Spinner animation="border" />
                    </div>
                  ) : (
                    <div
                      className="border-bottom custom-scrollbar"
                      style={{ height: 200 }}
                    >
                      {loginList.map((item) => (
                        <div className="border-bottom d-flex align-items-center justify-content-between mt-1">
                          <p className="mb-2">{item?.email}</p>
                          <div className="">
                            <button
                              className="btn theme-btn mb-2"
                              type="button"
                              style={{
                                fontSize: "14px",
                                padding: "5px 30px",
                              }}
                              onClick={() => deleteHotel(item?._id)}
                            >
                              Delete
                            </button>
                          </div>
                        </div>
                      ))}
                      {loginList.length === 0 && (
                        <h4 className="text-center mt-3">No Data</h4>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}

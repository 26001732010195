import React, { useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { notifyError, notifyTopRight } from "../common/Toaster";

export default function AddQuestion({ show, table, id, apiFunc, onHide }) {
  const [name, setName] = useState("");
  const [loader, setLoader] = useState(false);
  let errorsObj = {
    name: "",
  };
  const [errors, setErrors] = useState(errorsObj);
  async function onSubmit(e) {
    e.preventDefault();

    let error = false;
    const errorObj = { ...errorsObj };
    if (name === "") {
      errorObj.name = "Question is Required !";
      error = true;
    }
    setErrors(errorObj);
    if (error) {
      return;
    }
    setLoader(true);

    try {
      const response = await apiFunc(name, id);
      console.log(response, "get response");
      setName("");
      table(id);
      onHide();
      notifyTopRight(response.data.message);
    } catch (error) {
      notifyError(error?.response?.data?.message);
      console.log(error, "get error ");
    } finally {
      setLoader(false);
    }
  }
  return (
    <Modal className="modal fade" show={show} onHide={onHide} centered>
      <div className="" role="document">
        <div className="">
          <form onSubmit={onSubmit}>
            <div className="modal-body">
              <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
              <h3 className="">Add new Question</h3>
              <div className="add-contact-box">
                <div className="add-contact-content">
                  <div className="form-group mt-3 mb-3">
                    <label className="text-black font-w500">
                      Question library
                    </label>
                    <div className="contact-name">
                      <input
                        type="text"
                        className="form-input"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        placeholder="Enter question library"
                      />
                      <span className="validation-text"></span>
                      {errors.name && (
                        <div className="text-danger fs-12">{errors.name}</div>
                      )}
                    </div>
                  </div>
                  <div className="">
                    <button type="submit" className="btn theme-btn w-100">
                      {loader ? (
                        <Spinner animation="border" size="sm" />
                      ) : (
                        "CREATE"
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
}

import React, { useEffect, useState } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { notifyError, notifyTopRight } from "../common/Toaster";
import { addPrivacyStatementApi } from "../../services/Hotels/HotelsService";

export default function PrivacyStatement({
  show,
  onHide,
  hotelData,
  path,
  refresh,
}) {
  const [headLine, setHeadLine] = useState(hotelData?.privacyHeading);

  const [privacy, setPrivacy] = useState(hotelData?.privacyStatement);
  const [privacyError, setPrivacyError] = useState();
  const [loader, setLoader] = useState(false);

  async function handleSubmit() {
    if (privacy?.trim()?.length === "") {
      setPrivacyError("privacy statement cannot be empty");
      return;
    }
    setLoader(true);
    try {
      const response = await addPrivacyStatementApi(
        privacy,
        headLine,
        hotelData?._id
      );
      notifyTopRight("Updated privacy statement successfully");
      refresh();
      onHide();
    } catch (err) {
      notifyError(err?.response?.data?.message);
    } finally {
      setLoader(false);
    }
  }

  return (
    <Modal className="modal fade" show={show} onHide={onHide} centered>
      <div className="" role="document">
        <div className="">
          <div className="modal-body">
            <i className="flaticon-cancel-12 close" data-dismiss="modal"></i>
            <div className="add-contact-box p-3">
              <h4 className="modal-heading">Edit survey privacy statement</h4>
              <p className="error-text">{path}</p>
              <div className="mb-2">
                <label>Heading</label>
                <input
                  type="text"
                  value={headLine}
                  className="form-control"
                  onChange={(e) => {
                    setHeadLine(e.target.value);
                  }}
                />
                <span className="text-danger">{privacyError}</span>
              </div>
              <div className="mb-5">
                <label>Privacy statement</label>

                <textarea
                  type="text"
                  value={privacy}
                  rows={5}
                  className="form-control"
                  onChange={(e) => {
                    setPrivacy(e.target.value);
                    setPrivacyError("");
                  }}
                />
                <span className="text-danger">{privacyError}</span>
              </div>
              <div className="">
                <button
                  type="submit"
                  className="btn theme-btn w-100"
                  onClick={handleSubmit}
                >
                  {loader ? <Spinner animation="border" size="sm" /> : "Update"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

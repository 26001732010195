import { AREAS } from "../AuthApiEndPoints";
import axiosInstance from "../AxiosInstance";

export function getAreas(id, startDate, endDate) {
  return axiosInstance.get(
    AREAS + `?categoryId=${id}&startDate=${startDate}&endDate=${endDate}`
  );
}
export function getAllAreas(id, groupId, startDate, endDate) {
  return axiosInstance.get(
    AREAS +
      `?categoryId=${id}&groupId=${groupId}&startDate=${startDate}&endDate=${endDate}`
  );
}

export function createAreas(Name, categoryId) {
  const postData = {
    name: Name,
    categoryId,
  };
  return axiosInstance.post(AREAS, postData);
}
